import examples from "libphonenumber-js/examples.mobile.json";
import {
  getExampleNumber,
  parsePhoneNumberFromString
} from "libphonenumber-js";

export const phoneNumberValidationMixin = {
  data() {
    return {
      phone_example: getExampleNumber("CH", examples).formatInternational()
    };
  },
  props: {},
  methods: {
    validatePhoneNumber(value, vm, key, isOptional) {
      if (isOptional && (value === null || value.length === 0)) {
        return true;
      }
      const parsing = parsePhoneNumberFromString(value, "CH");
      if (parsing) {
        vm[key] = parsing.formatInternational();
        return parsing.isValid();
      }
      return false;
    }
  }
};
